
import server from '@/request';

// 发票申请新增
const addInvoice = (params)=> {
  return server.post('/xiaozi-xmb/wireless/saas/invoice/add', params);
};

// 发票申请删除
const deleteInvoice = (params)=> {
  return server.post('/xiaozi-xmb/wireless/saas/invoice/delete', params);
};

// 发票申请详情
const detailInvoice = (params)=> {
  return server.post('/xiaozi-xmb/wireless/saas/invoice/queryDetail', params);
};

// 发票申请列表
const getInvoiceLists = (params)=> {
  return server.post('/xiaozi-xmb/wireless/saas/invoice/queryPage', params);
};

// 发票申请更新
const updateInvoice = (params)=> {
  return server.post('/xiaozi-xmb/wireless/saas/invoice/update', params);
};

// 可用发票类型列表
const getEnableInvoiceTypeList = (params)=> {
  return server.post('/xiaozi-xmb/wireless/saas/invoice/enableInvoiceTypeList', params);
};
export {
  addInvoice,
  deleteInvoice,
  detailInvoice,
  getInvoiceLists,
  updateInvoice,
  getEnableInvoiceTypeList,
};
